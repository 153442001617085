&__titel {
  @include h1--enormous;
  color: $color-primary;
  text-align: center;
  margin: 0;

  @include font-inherit;
}

&__subtitel {
  @include p--medium;
  color: $color-secondary;
  text-align: center;

  @include font-inherit;
}

&__categorie {
  position: relative;
  background: transparent;
  transition: 0.3s ease;
  cursor: pointer;
  margin-top: 2.5rem;

  //Zo oncharmant maar wel duidelijk
  @at-root &#{$this}__categorie_open {
    background: $color-primary;
  }
}

&__categorie_naam {
  position: relative;
  padding: .625rem 0;
  border-bottom: .125rem solid $color-secondary;
  transition: 0.3s ease;

  @include h2;
  color: $color-primary;
  margin: 0;
  letter-spacing: -0.03em;
  transition: 0.3s ease;

  @include font-inherit;
  @include no-spacing;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3.125rem;
    height: 1.875rem;
    background-image: url(/images/default/svg/icons/chevron--down.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  @at-root #{$this}__categorie_open & {
    padding-right: calc(100% - 5.5em);
    border-bottom: 0.125rem solid transparent;
    margin-bottom: 4rem;

    @media(max-width: 768px){
      margin-bottom: 2rem;
    }

    @include h1--enormous;
    @include font-inherit;
    @include no-spacing;
    color: #fff;

    &:after {
      transform: translateY(-50%) rotate(180deg);
      top: 4rem;
    }
  }
}

&__vieringen_container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  transition: 0.3s ease;
  cursor: auto;
}

&__vieringen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 3.75rem;
}

&__viering {
  position: relative;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border-bottom: 1px solid $color-secondary;
  margin-bottom: 2.5rem;
  padding: 0 0 .625rem;

  @media(max-width: 768px){
    flex-direction: column;
  }

  &.--header {
    border-bottom: none;
    
    @media(max-width: 768px){
      display: none;
    }
  }
}

&__edit {
  position: absolute;
  right: 0;
  top: 0;
  
  button {
    cursor: pointer;
  }
}

&__viering_cell {
  width: 25%;
  color: #fff;
  @media(max-width: 768px){
    margin-top: 0.5rem;
  }

  p {
    margin: 0;
  }

  &.--datum {
    width: 14%;
    text-align: center;
    @media(max-width: 768px){
      width: 100%;
      text-align: left;
    }
  }

  &.--spreker {
    width: 30%;
    @media(max-width: 768px){
      width: 100%;
    }
  }

  &.--type {
    width: 20%;
    @media(max-width: 768px){
      width: 100%;
      font-weight: bold;
    }
  }

  &.--extra {
    width: 36%;
    padding-right: 2.5rem;
    @media(max-width: 768px){
      width: 100%;
    }
  }

  &.--actie {
    width: 54%;
    @media(max-width: 768px){
      width: 100%;
      font-weight: bold;
    }
  }

  &.--loc {
    width: 32%;
    padding-right: 2.5rem;
    @media(max-width: 768px){
      width: 100%;
    }
  }
}